import React, { useState } from "react";
import { IoMdMenu } from "react-icons/io";
import { motion } from "framer-motion";
import { FaGraduationCap } from "react-icons/fa";
import { NavbarMenu } from "../../mockData/data.js";
import Logo from "../../assets/Logo.png";
import sefasoft from "../../assets/sefasoft.png";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="relative z-20">
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        className="container py-2 flex justify-between items-center"
      >
        {/* Logo sefasoft */}
        <div className="flex items-center gap-2">
          <img src={sefasoft} alt="sefasoft" className="h-10 w-30" />
        </div>
        

        {/* Menu section AND logo */}
        <div className=" lg:block">
          <ul className="flex items-center ">
            {/* Menu section for larger screens */}
            {/*{NavbarMenu.map((menu) => (
              <li key={menu.id}>
                <a
                  href={menu.path}
                  className="inline-block py-2 px-3 text-l font-medium tracking-wide text-gray-800 hover:text-primary hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-primary cursor-pointer transition-all"
                >
                  {menu.title}
                </a>
              </li>
            ))} */}

            {/* logo */}
            <img src={Logo} alt="Logo" className=" h-30 w-60 " />
          </ul> 
              
        </div>

        {/* Mobile menu toggle 
        <div className="lg:hidden">
          <IoMdMenu className="text-4xl cursor-pointer" onClick={handleClick} />
          
        </div>*/}
      </motion.div>     
    </nav>
  );
};

export default Navbar;