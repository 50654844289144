import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { annexApi } from "./api/scholineApi.js";


export const store = configureStore({
  reducer: {
    [annexApi.reducerPath]: annexApi.reducer,
    

  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(annexApi.middleware);
  },
});

setupListeners(store.dispatch);

export {
  useCreateRegistrationMutation,
} from "./api/scholineApi.js";

