import React from "react";
import Navbar from "../Navbar/Navbar.js";
import { IoIosArrowRoundForward } from "react-icons/io";
import HeroPng from "../../assets/hero.png";
import bg from "../../assets/bg.png";
import { motion } from "framer-motion";
import { MotionUp } from "../../utility/animation.js";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import frame from "../../assets/frame.png";

//import { QRCodeSVG } from "qrcode.react";

const Hero = () => {
  const navigate = useNavigate();

  const handleRegisterClick = () => {
    navigate("/register");
  };

  const handleLoginClick = () => {
    window.location.href = "/login";
  };

  return (
    <section
      className="bg-cover bg-center bg-no-repeat overflow-hidden relative w-screen h-screen"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <Navbar />

      <div className="container grid grid-cols-1 md:grid-cols-2 ">
        {/* Hero Image */}
        <div className="flex justify-center items-center ">
          <motion.img
            initial={{ x: 50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.4, ease: "easeInOut" }}
            src={HeroPng}
            alt=""
            className="w-[400px] xl:w-[700px] relative z-10 drop-shadow"
          />
        </div>

        {/* Brand Info */}
        <div className=" flex flex-col justify-center items-center md:items-end py-6 md:py-0 relative  ">
          <div className="text-center md:text-right space-y-16 ">
            <motion.h1
              variants={MotionUp(0.6)}
              initial="initial"
              animate="animate"
              className="text-3xl text-primary lg:text-6xl font-bold !leading-snug "
            >
              Inscriptions Ouvertes <br />
              <span className="text-secondary">All-in-one plateforme</span>
              <p className="text-gray-500 mt-4  md:text-base lg:text-lg font-medium">
                <span className="text-black font-bold text-lg">
                  Obtenez 10% de réduction dès aujourd’hui !
                </span>
                <br />
                Inscrivez-vous maintenant et profitez de ce coupon de réduction.
              </p>
            </motion.h1>
            <motion.div
              variants={MotionUp(0.8)}
              initial="initial"
              animate="animate"
              className="flex justify-center md:justify-end"
            >
              <button
                onClick={handleLoginClick}
                className="primary-btn flex items-center gap-2 group  mr-10"
              >
                Login
                <IoIosArrowRoundForward className="text-xl group-hover:translate-x-2 group-hover:-rotate-45 duration-300" />
              </button>

              <button
                onClick={handleRegisterClick}
                className="secondary-btn flex items-center gap-2 group "
              >
                Register
                <IoIosArrowRoundForward className="text-xl group-hover:translate-x-2 group-hover:-rotate-45 duration-300" />
              </button>
            </motion.div>
          </div>
          {/* Social Media Links */}
          <motion.div
            variants={MotionUp(0.8)}
            initial="initial"
            animate="animate"
            className="flex justify-center md:justify-start mt-8 space-x-4"
          >
            <a
              href="https://facebook.com"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-gray-200 p-3 rounded-full hover:bg-gray-300 transition"
            >
              <FaFacebookF className="text-blue-600" />
            </a>
            <a
              href="https://twitter.com"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-gray-200 p-3 rounded-full hover:bg-gray-300 transition"
            >
              <FaTwitter className="text-blue-400" />
            </a>
            <a
              href="https://instagram.com"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-gray-200 p-3 rounded-full hover:bg-gray-300 transition"
            >
              <FaInstagram className="text-pink-500" />
            </a>
            <a
              href="https://linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-gray-200 p-3 rounded-full hover:bg-gray-300 transition"
            >
              <FaLinkedinIn className="text-blue-700" />
            </a>
          </motion.div>

          {/* QR Code Section */}
          <motion.div
            variants={MotionUp(0.8)}
            initial="initial"
            animate="animate"
            className="flex justify-center md:justify-end"
          >
            <a
              href="https://docsend.com/view/4j3vga5gj5dyamdd"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="mt-4 space-x-4">
                <img src={frame} alt="frame" className="h-40 w-40" />
              </div>
            </a>

            {/* Arrow pointing to QR code 
              <div className="relative ">
                <div className="absolute ">
                
                </div>
                <div className="mt-8 space-x-4 rounded-lg shadow-lg ">
                  <QRCodeSVG
                    value="https://docsend.com/view/4j3vga5gj5dyamdd"
                    size={70}
                    bgColor="#ffffff"
                    fgColor="#495d9f"
                  />
                </div>
              </div>*/}
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
