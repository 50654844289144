import React, { useState, useEffect } from "react";
import { useCreateRegistrationMutation } from "../../store/api/scholineApi.js";
import { motion } from "framer-motion"; 
import { IoIosArrowRoundForward } from "react-icons/io";
import bg from "../../assets/bg.png";
import HeroPng from "../../assets/hero.png";
import Logo from "../../assets/Logo.png";

const RegisterPage = () => {
  const [FirstName, setFirstname] = useState("");
  const [LastName, setLastname] = useState("");
  const [school, setSchool] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isRegistered, setIsRegistered] = useState(false);  

  const [createRegistration, { data, error, isLoading }] = useCreateRegistrationMutation();

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrorMessage("");
    createRegistration({ FirstName, LastName, school, phone, email });
  };

  useEffect(() => {
    if (data) {
      console.log("Registration successful:", data);
      setIsRegistered(true);  
    }
    if (error) {
      console.log("Registration failed with error:", error);
      setErrorMessage(error?.data?.message || "Registration failed. Please try again.");
    }
  }, [data, error]);

  const handleDownloadPresentation = () => {
    console.log("Downloading the presentation...");
  };

  return (
    <motion.div
      className="flex justify-center items-center min-h-screen bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url(${bg})` }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <div className="container grid grid-cols-1 md:grid-cols-2 justify-center items-center">
        {/* Hero Image */}
        <motion.div className="flex justify-center items-center" initial={{ x: -100 }} animate={{ x: 0 }} transition={{ duration: 1 }}>
          <motion.img src={HeroPng} alt="Hero" className="w-full h-auto object-cover" />
        </motion.div>

        {/* Registration Form */}
        {!isRegistered ? (
          <motion.div 
            className="flex justify-center items-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            <div className="w-full max-w-md bg-white p-8 rounded-lg shadow-xl">
              <div className="text-center mt-2">
                <motion.img src={Logo} alt="Logo" className="w-30 h-20 mx-auto" />
              </div>

              <h2 className="text-2xl font-bold text-center mb-6">Create Your Account</h2>
              {errorMessage && <p className="text-red-500 text-sm text-center">{errorMessage}</p>}

              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <label className="block text-sm font-semibold text-gray-700">FirstName</label>
                  <input
                    type="text"
                    value={FirstName}
                    onChange={(e) => setFirstname(e.target.value)}
                    className="w-full px-4 py-2 mt-2 border border-gray-300 rounded-md"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-semibold text-gray-700">LastName</label>
                  <input
                    type="text"
                    value={LastName}
                    onChange={(e) => setLastname(e.target.value)}
                    className="w-full px-4 py-2 mt-2 border border-gray-300 rounded-md"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-semibold text-gray-700">School </label>
                  <input
                    type="text"
                    value={school}
                    onChange={(e) => setSchool(e.target.value)}
                    className="w-full px-4 py-2 mt-2 border border-gray-300 rounded-md"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-semibold text-gray-700">phone </label>
                  <input
                    type="text"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    className="w-full px-4 py-2 mt-2 border border-gray-300 rounded-md"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-semibold text-gray-700">E-mail</label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full px-4 py-2 mt-2 border border-gray-300 rounded-md"
                    required
                  />
                </div>
                <div className="mt-4">
                  <button
                    type="submit"
                    className={`w-full py-2 text-white font-semibold rounded-lg transition ${isLoading ? "bg-gray-400 cursor-not-allowed" : "bg-primary hover:bg-secondary"}`}
                    disabled={isLoading}
                  >
                    {isLoading ? "Registering..." : "Register Now"}
                  </button>
                </div>
              </form>
            </div>
          </motion.div>
        ) : ( 
          <motion.div 
            className="flex justify-center items-center m-20 mt-10 w-4/5 h-50 bg-white p-8 rounded-lg shadow-xl border-solid border-4 border-primary"
            initial={{ opacity: 0 }}
            animate={{ opacity: 2 }}
            transition={{ duration: 2 }}
          >
            <div className="text-center ">
              <h2 className="text-2xl font-bold mb-4">Votre demande a été acceptée.</h2>
              <p>Veuillez attendre une réponse plus tard.</p>
              <div className="mt-14">
                <a
                  href="https://docsend.com/view/4j3vga5gj5dyamdd"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-secondary hover:bg-primary text-white font-bold py-2 px-4 rounded inline-flex items-center secondary-btn  gap-2 group"
                >
                  Aller à la présentation
                    <IoIosArrowRoundForward className="text-xl group-hover:translate-x-2 group-hover:-rotate-45 duration-300" />
                </a>
              </div>
            </div>
          </motion.div>
        )} 
      </div>
    </motion.div>
  );
};

export default RegisterPage;
