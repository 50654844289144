import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Hero from "./components/Hero/Hero.js";
import RegisterPage from "./components/RegisterPage/RegisterPage.js";

const App = () => {
  return (
    <Router> 
      <main className="overflow-x-hidden bg-white text-dark">
        <Routes>
          <Route path="/" element={<Hero />} />
          <Route path="/register" element={<RegisterPage />} />
        </Routes>
      </main>
    </Router>
  );
};

export default App
