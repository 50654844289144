import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const annexApi = createApi({
  reducerPath: "annexApi",
  tagTypes: ["FetchAnnexes"],
  baseQuery: fetchBaseQuery({
    baseUrl: "https://www.scholine.com",
  }),
  endpoints(builder) {
    return {
      createRegistration: builder.mutation({
        invalidatesTags: ["FetchAnnexes"],
        query: ({ FirstName, LastName,  school, phone, email }) => {
          return {
            url: "/api/admin/annex",
            method: "POST",
            body: {
              FirstName, 
              LastName, 
              school, 
              phone, 
              email 
              },
          };
        },
      }),

    };
  },
});

export const {
  useCreateRegistrationMutation,
} = annexApi;
export { annexApi };
